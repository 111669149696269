import React from 'react';

import {StaticImage} from 'gatsby-plugin-image';

import Layout from '../components/Layout';

export default function Index() {
	return (
		<Layout
			title="Herzlich willkommen"
			pageHeader={
				<p>
					<StaticImage src="../photos/foto-fahrzeuge-index-cropped.jpg" alt="Fahrzeuge" />
				</p>
			}
		>
			<h2>Herzlich willkommen bei der BRK Bereitschaft Bad Reichenhall</h2>
			<p>
				Seit der Gründung der Frauenbereitschaft im Jahre 1881 und der Sanitätskolonne im Jahre 1892 sind wir im
				Saalachtal für Sie ehrenamtlich im Sanitätsdienst und Katastrophenschutz tätig.
			</p>
		</Layout>
	);
}
